import React from "react"
import Navbar from "./../../components/Navbar/Navbar"
import {
  Accordion,
  Card,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap"
import axios from "axios"
import { saveAs } from "file-saver"
import FirebaseApp from "./../../config/firebase"
import { navigate } from "gatsby" //import navigate from gatsby
import { GrFormDown } from "react-icons/gr"
import { AiOutlineDownload } from "react-icons/ai"
import { RiUploadCloudFill } from "react-icons/ri"
import { BsCheckCircle } from "react-icons/bs"
import EasypasiaLogo from "./../../images/easypaisa.png"
import Jazzcashlogo from "./../../images/jazzcashlogo.png"
import UBLLogo from "./../../images/ubl-united-bank-limited-logo.png"
import { FiCheck } from "react-icons/fi"
import moment from "moment-timezone"
import "./index.css"
let database = FirebaseApp.database().ref("/")
export default class Courses extends React.Component {
  constructor() {
    super()
    this.state = {
      show: true,
      name: "suneel kumar",
      price1: "52465324",
      price2: 544564,
      receiptId: "one",
      appliedCourse: [],
      approved: [],
      url: "",
      course: "",
      user: {},
      isLoader: false,
      serialNumber: 0,
      bodyLoader: true,
      notificationTitle: "",
      notificationDes: "",
      uploadLoader: false,
      imagepath: "",
      onTimeChalanDownload: false,
      checked: "Monthly",
      _image_type_error: "",
      uid: "",
    }
  }

  componentDidMount = async () => {
    FirebaseApp.auth().onAuthStateChanged(async user => {
      if (user && user.emailVerified) {
        // database.child("/SerialNumber").set({serialNumber:0})
        this.setState({ uid: user.uid })
        database.child("Registration/" + user.uid).once("value", res => {
          let data = res.val()

          if (data.courseType !== "1/1 Course") {
            if (data.course === "Web Development & Web Designing") {
              data.totalFees = 23000
            } else if (data.course === "Mobile App Development") {
              data.totalFees = 14000
            } else if (data.course === "Digital Marketing") {
              data.totalFees = 14000
            } else if (data.course === "Video Editing") {
              data.totalFees = 14000
            } else if (data.course === "Wordpress") {
              data.totalFees = 14000
            } else if (data.course === "E-commerce Course") {
              data.totalFees = 14000
            } else if (data.course === "Graphic Design") {
              data.totalFees = 14000
            } else if (data.course === "Freelancing") {
              data.totalFees = 2500
            } else if (data.course === "Social Media Marketing") {
              data.totalFees = 2999
            } else if (data.course === "Youtube Mystery") {
              data.totalFees = 2399
            }
          } else {
            if (data.course === "Digital Marketing") {
              data.addmissionFees = 15000
              data.totalFees = 15000
              data.amountInWords = "Fifteen Thousands only="
            } else if (data.course === "Wordpress") {
              data.addmissionFees = 15000
              data.totalFees = 15000
              data.amountInWords = "Fifteen Thousands only="
            } else if (data.course === "E-commerce Course") {
              data.addmissionFees = 15000
              data.totalFees = 15000
              data.amountInWords = "Fifteen Thousands only="
            }
          }
          this.setState({ user: data, bodyLoader: false })
        })
        database.child("Alert/").once("value", res => {
          var notification = res.val()
          this.setState({
            notificationTitle: notification.title,
            notificationDes: notification.Alert,
          })
        })
      } else {
        navigate("/")
      }
    })
  }

  createAndDownloadPdf = async () => {
    this.setState({ isLoader: true })
    let { user, serialNumber, checked } = this.state
    await database.child("SerialNumber/").once("value", res => {
      var snumber = res.val()
      serialNumber = snumber.serialNumber
      this.setState({ serialNumber: snumber.serialNumber })
    })
    let obj = {
      name: user.name,
      fname: user.fatherName,
      nic: user.nicNumber,
      course: user.course,
      sNumber: serialNumber,
      fees: user.addmissionFees,
      amountInWords: user.amountInWords,
    }

    axios
      .post("https://chalangenerator.herokuapp.com/createchalan", obj)
      .then(() =>
        axios.get("https://chalangenerator.herokuapp.com/chalan-pdf", {
          responseType: "blob",
        })
      )
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })
        saveAs(pdfBlob, "iSkillersChalan.pdf")
        this.setState({ isLoader: false })
      })
    database
      .child("SerialNumber/")
      .set({ serialNumber: this.state.serialNumber + 1 })
  }

  createOneTimeChalan = async () => {
    this.setState({ onTimeChalanDownload: true })
    let { user, serialNumber, checked } = this.state
    await database.child("SerialNumber/").once("value", res => {
      var snumber = res.val()
      serialNumber = snumber.serialNumber
      this.setState({ serialNumber: snumber.serialNumber })
    })
    let obj = {
      name: user.name,
      fname: user.fatherName,
      nic: user.nicNumber,
      course: user.course,
      sNumber: serialNumber,
      fees: user.addmissionFees,
      amountInWords: user.amountInWords,
    }
    if (user.course === "Web Development & Web Designing") {
      var totalfees = 23000
      var tenPer = (totalfees * 10) / 100
      obj.fees = totalfees - tenPer
      obj.amountInWords = "twenty thousands Seven hundred only/="
    } else if (user.course === "Freelancing") {
      var totalfees = 15000
      obj.fees = totalfees
      obj.amountInWords = "Fifteen thousands only/="
    } else {
      var totalfees = 14000
      var tenPer = (totalfees * 10) / 100
      obj.fees = totalfees - tenPer
      obj.amountInWords = "Tweleve thousands six hundred only/="
    }

    axios
      .post("https://chalangenerator.herokuapp.com/createchalan", obj)
      .then(() =>
        axios.get("https://chalangenerator.herokuapp.com/chalan-pdf", {
          responseType: "blob",
        })
      )
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })
        saveAs(pdfBlob, "iSkillersChalan.pdf")
        this.setState({ onTimeChalanDownload: false })
      })
    database
      .child("SerialNumber/")
      .set({ serialNumber: this.state.serialNumber + 1 })
  }

  getImageURL = async e => {
    if (e.target.files.length !== 0) {
      this.setState({ _image_type_error: "" })
      // var uid = localStorage.getItem("user")
      // uid = JSON.parse(uid)

      let { uid } = this.state

      let imageName = e.target.files[0].name
      var imageType = e.target.files[0].type
      if (imageType === "image/png" || imageType === "image/jpeg") {
        this.setState({ imagepath: e.target.files[0].name, uploadLoader: true })
        let ref = FirebaseApp.storage().ref("/").child(`uploadChalan/${uid}`)
        await ref.put(e.target.files[0])
        ref.getDownloadURL().then(url => {
          this.setState({
            uploadLoader: true,
            url: url,
          })
        })
      } else {
        this.setState({
          _image_type_error: "Please select file in jpeg or png format...",
        })
      }
    } else {
      this.setState({ _image_type_error: "" })
    }
  }

  uploadChalan = () => {
    let { user } = this.state
    var currentDate = moment().add(2, "month").format("DD/MM/YY")
    // console.log("=====todays date========",currentDate.format("DD/MM/YY"))
    database.child("Registration/" + user.uid + "/").update({
      paytype:
        user.courseType === "1/1 Course" || user.registrationType === "online"
          ? "onetime"
          : user.course !== "Freelancing" ||
            user.course === "Youtube Mystery" ||
            user.course === "Social Media Marketing"
          ? this.state.checked
          : "onetime",
      approved: "Pending",
      PaidChalan: this.state.url,
      isCompleted: "inProgress",
      feesPaidDate: currentDate,
    })
    database
      .child(
        "Registration/" + this.state.user.uid + "/" + "monthlyFeesStructure"
      )
      .update({ monthlyPaid: false })
    window.location.reload(false)
  }

  render() {
    let { appliedCourse, user, url, checked, _image_type_error } = this.state

    return (
      <div style={{ height: "auto", backgroundColor: "#fae6ff" }}>
        <Navbar profile={user.url} />

        {this.state.show ? (
          <div className="_announcement">
            <Alert
              variant="primary"
              onClose={() => this.setState({ show: false })}
              dismissible
            >
              <Alert.Heading className="announce_heading">
                {" "}
                <img
                  src={require("./../../images/announcement.png")}
                  style={{
                    height: "40px",
                    width: "40px",
                    marginBottom: "-1px",
                    marginRight: 5,
                  }}
                />
                {this.state.notificationTitle}
              </Alert.Heading>
              <div className="_announcement_text">
                {this.state.notificationDes}
              </div>
            </Alert>
          </div>
        ) : null}
        <div className="_welcomeTitle">
          Welcome to iHunar students Portal
        </div>
        {/* {
          user.approved === "Pending" || user.approved === "Approved" ? null : <div className="_welcomeTxt">You will be able to download challan copy and admit card they are available below</div>
        } */}

        {this.state.bodyLoader ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <Spinner
              as="span"
              animation="grow"
              size="lg"
              role="status"
              aria-hidden="true"
              style={{ color: "purple" }}
            />
          </div>
        ) : (
          <>
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg="10">
                  <Accordion style={{ margin: "5px" }} defaultActiveKey="1">
                    <Card>
                      <Card.Header
                        className="_accordionToggle"
                        onClick={() =>
                          user.approved === "Approved"
                            ? navigate("/courseInstructions")
                            : null
                        }
                      >
                        <Accordion.Toggle
                          variant="link"
                          eventKey="1"
                          className="_accordionToggleDiv"
                        >
                          <div className="_courseTitle">
                            {user.approved === "Approved" ? (
                              <img
                                src={require("./../../images/verified.png")}
                                style={{
                                  height: 20,
                                  width: 20,
                                  verticalAlign: "center",
                                  marginBottom: -0.5,
                                }}
                              />
                            ) : (
                              <>
                                <span className="_lable">
                                  {user.approved === "Pending"
                                    ? "Pending"
                                    : null}
                                </span>
                                <span className="_lable">
                                  {user.approved === "Reject" ? "Reject" : null}
                                </span>
                              </>
                            )}
                            <span style={{ marginLeft: "10px" }}>
                              {user.course}
                            </span>
                          </div>
                          <div>
                            <GrFormDown size="30" color="grey" />
                          </div>
                        </Accordion.Toggle>
                      </Card.Header>
                      {user.approved !== "Approved" ? (
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            {user.approved !== "Pending" ? (
                              <div>
                                {/* <div class="_download_view">
                                    <img src={require('./../../images/pdf-file.png')} style={{ height: 60, width: 60 }} />
                                    {user.course !== "Freelancing" ?
                                      <div style={{ padding: "10px", fontSize: "14px" }}>Get 10% off if you want to pay in one time</div> : null}
                                    {
                                      user.course === "Freelancing" ?
                                        <div className="_onTimePaidChalanDiv">
                                          <button className="_download_btn" onClick={() => this.createAndDownloadPdf()} >
                                            {
                                              this.state.isLoader ? <><Spinner as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" /> &nbsp; &nbsp; Downloading....</> :
                                                <><AiOutlineDownload size="20" style={{ marginRight: 5 }} /><div>Download challan</div></>
                                            }
                                          </button>
                                          {user.course !== "Freelancing" ?

                                            <div style={{ textAlign: "center" }} className="_onTimePaidChalanTxt">If you want to pay your fees monthly.</div> : null}
                                        </div> :
                                        <Row className="justify-content-center">

                                          <Col lg={6} xs={12}>
                                            <div className="_onTimePaidChalanDiv">
                                              <button className="_download_btn" onClick={() => this.createOneTimeChalan()} >
                                                {
                                                  this.state.onTimeChalanDownload ? <><Spinner as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" /> &nbsp; &nbsp; Downloading....</> :
                                                    <><AiOutlineDownload size="20" style={{ marginRight: 5 }} /><div>Download challan</div></>
                                                }
                                              </button>
                                              <div className="_onTimePaidChalanTxt">If you want to pay your fees in one time </div>
                                            </div>
                                          </Col>
                                          <Col lg={6} xs={12}>
                                            <div className="_onTimePaidChalanDiv">
                                              <button className="_download_btn" onClick={() => this.createAndDownloadPdf()} >
                                                {
                                                  this.state.isLoader ? <><Spinner as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" /> &nbsp; &nbsp; Downloading....</> :
                                                    <><AiOutlineDownload size="20" style={{ marginRight: 5 }} /><div>Download challan</div></>
                                                }
                                              </button>
                                              <div style={{ textAlign: "center" }} className="_onTimePaidChalanTxt">If you want to pay your fees monthly.</div>
                                            </div>
                                          </Col>
                                        </Row>
                                    }
                                    <div style={{ fontSize: 12, paddingTop: 20 }}>Download your challan and pay it to UBL Bank in order to proceed your admission further.</div>
                                  </div> */}
                                <div>
                                  <table className="_course_table_main">
                                    <thead className="course_table_thead">
                                      <tr className="_course_table_tr">
                                        <th
                                          className="_course_table_th_td  _course_table_th"
                                          scope="col"
                                        >
                                          Applicant Name
                                        </th>
                                        <th
                                          className="_course_table_th_td _course_table_th"
                                          scope="col"
                                        >
                                          Father's Name
                                        </th>
                                        <th
                                          className="_course_table_th_td _course_table_th"
                                          scope="col"
                                        >
                                          CNIC/B-Form
                                        </th>
                                        <th
                                          className="_course_table_th_td _course_table_th"
                                          scope="col"
                                        >
                                          Course
                                        </th>
                                        <th
                                          className="_course_table_th_td _course_table_th"
                                          scope="col"
                                        >
                                          Amount/Rs
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="course_table_thead">
                                      <tr class="_course_table_tr">
                                        <td
                                          className="_course_table_th_td _course_table_td"
                                          data-label="Applicant Name"
                                        >
                                          {user.name}
                                        </td>
                                        <td
                                          className="_course_table_th_td _course_table_td"
                                          data-label="Father's Name"
                                        >
                                          {user.fatherName}
                                        </td>
                                        <td
                                          className="_course_table_th_td _course_table_td"
                                          data-label="CNIC/B-Form"
                                        >
                                          {user.nicNumber}
                                        </td>
                                        <td
                                          className="_course_table_th_td _course_table_td"
                                          data-label="Course"
                                        >
                                          {user.course}
                                        </td>
                                        <td
                                          className="_course_table_th_td _course_table_td course_amount"
                                          data-label="Amount/Rs"
                                        >
                                          <span className="_course_table_th_td _course_table_td">
                                            {user.course ===
                                              "Mobile App Development" &&
                                              "Admission & Advance Monthly Fees "}
                                          </span>
                                          {user.addmissionFees}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  style={{
                                    fontSize: 12,
                                    paddingTop: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  Pay with below given UBL Bank account.
                                </div>
                                <div style={{ marginTop: 20 }}>
                                  <Row className="justify-content-center">
                                    {/* <Col lg="3">
                                      <div style={{ textAlign: "center" }}>
                                        <div style={{ color: "grey" }}>
                                          03322511711
                                        </div>
                                        <img
                                          src={EasypasiaLogo}
                                          width="100px"
                                          className="_easypasiaLogo"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="3">
                                      <div style={{ textAlign: "center" }}>
                                        <div style={{ color: "grey" }}>
                                          03322511711
                                        </div>
                                        <img src={Jazzcashlogo} width="100px" />
                                      </div>
                                    </Col> */}
                                    <Col lg="3">
                                      <div style={{ textAlign: "center" }}>
                                        <div style={{ color: "grey" }}>
                                          270466645
                                        </div>
                                        <img src={UBLLogo} width="100px" />
                                        <div
                                          style={{
                                            fontSize: 12,
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Branch Code: 1941
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              <p>
                                Your challan sent successfully, you will be
                                notify when your registration will be approved.{" "}
                              </p>
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      ) : null}
                    </Card>
                  </Accordion>
                </Col>
              </Row>
            </Container>

            {user.approved !== "Approved" && user.approved !== "Pending" ? (
              <>
                <div className="_paid_chalan">
                  Upload your paid receipt here .
                </div>
                {user.course !== "Freelancing" ? (
                  user.registrationType === "online" ? null : (
                    <p className="_checkbox_row">
                      <div
                        className="_checkbox"
                        onClick={() => this.setState({ checked: "Monthly" })}
                      >
                        {" "}
                        {checked === "Monthly" ? (
                          <FiCheck size={12} color="purple" />
                        ) : null}{" "}
                      </div>
                      Monthly Challan
                      {/* <div className="_checkbox" onClick={() => this.setState({ checked: "onetime" })}> {checked === "onetime" ? <FiCheck size={12} color="purple" /> : null} </div>
                    One time challan */}
                    </p>
                  )
                ) : null}
                <p className="notePara">
                  <b>Note:</b> Admission fees will not be refund!
                </p>
                <div className="_uploadChalanDiv">
                  {user.approved !== "Approved" &&
                  user.approved !== "Pending" ? (
                    <>
                      <label className="custom-file-upload">
                        {url === "" ? (
                          <>
                            {this.state.uploadLoader ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                style={{ color: "purple" }}
                                aria-hidden="true"
                              />
                            ) : (
                              <>
                                <input
                                  type="file"
                                  onChange={this.getImageURL}
                                />
                                <RiUploadCloudFill
                                  size="20"
                                  style={{ marginRight: 5 }}
                                />
                                <span>
                                  <div
                                    style={{ fontSize: 12, paddingBottom: 20 }}
                                  >
                                    Select photo of the paid receipt
                                  </div>
                                </span>
                              </>
                            )}
                          </>
                        ) : null}
                        {url ? (
                          <>
                            <BsCheckCircle
                              style={{ color: "green", fontSize: 30 }}
                            />
                            <div
                              style={{ textAlign: "center", fontSize: "10px" }}
                            >
                              {this.state.imagepath}
                            </div>
                          </>
                        ) : null}
                      </label>
                    </>
                  ) : (
                    <BsCheckCircle style={{ color: "green", fontSize: 40 }} />
                  )}

                  <div className="_image_type_error">{_image_type_error}</div>
                  {user.approved !== "Approved" &&
                  user.approved !== "Pending" ? (
                    <p
                      style={{
                        textAlign: "right",
                        width: "45%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <button
                        className="_download_btn"
                        disabled={url !== "" ? false : true}
                        onClick={() => this.uploadChalan()}
                      >
                        {" "}
                        <RiUploadCloudFill
                          size="20"
                          style={{ marginRight: 5 }}
                        />
                        <div>Send Receipt</div>
                      </button>
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    )
  }
}
